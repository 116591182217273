<template>
  <page-view title="绑定手机" left-arrow white-bg>
    <van-form
      v-if="shouldShowForm"
      :show-error="true"
      :show-error-message="false"
      :scroll-to-error="true"
      :validate-first="true"
      @submit="onSubmit"
      @failed="onFailed"
    >
      <div class="driver-box"></div>
      <van-field
        v-model="form.phone"
        name="phone"
        label="手机号"
        type="tel"
        :maxlength="11"
        :formatter="formatterNumber"
        placeholder="请输入手机号"
        :rules="[
          { required: true, message: '请输入手机号' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
        ]"
      />
      <van-field v-model="form.sms" name="sms" center clearable label="验证码" placeholder="请输入短信验证码">
        <template #button>
          <!-- <van-button size="small" type="primary" native-type="button" @click="getSMSCode">获取验证码</van-button> -->
          <sms-code-button v-model="form.phone" @click="getSMSCode"/>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit" :loading="submiting" :disabled="submiting" loading-text="正在提交...">提 交</van-button>
      </div>
    </van-form>

    <div
      v-else
      :style="{
        display: 'flex',
        flexDirection: 'column',
        flex: 'auto',
        alignItems: 'center',
        margin: '32px 16px'
      }"
    >
      <img src="@/assets/images/my/bind-mobile.jpg" :style="{width: '220px'}" />
      <p :style="{margin: '20px 0'}">
        <svg-icon icon-class="link" :style="{color: '#1989fa'}"/>
        <span class="ml-10">已绑定号码：{{this.phone}}</span>
      </p>
      <van-button block type="primary" @click="isEditMode = true">更换手机号</van-button>
    </div>
  </page-view>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from "vuex";

import { 
  wXGZHService_sendWxgzhDxyzm, 
  wXGZHService_addYhsjhAndYxhh 
} from "@/api/grzx";
import SmsCodeButton from '@/components/SmsCodeButton';
export default {
  name: "BindMobile",
  components: {
    SmsCodeButton
  },
  data() { 
   return {
      form: {
        phone: "",
        sms: "",
      },
      submiting: false,
      isEditMode: false,
    };
  },
  computed: {
    ...mapGetters(["openId","phone"]),
    shouldShowForm() {
      console.log(this.isEditMode || !this.phone)
      return this.isEditMode || !this.phone;
    },
  },
  methods: {
    formatterNumber(value) {
      // 过滤输入的非数字
      return value.replace(/\D/g, "");
    },
    onSubmit: _.debounce(function(values) {
      console.log(values);
      this.submiting = true;
      wXGZHService_addYhsjhAndYxhh(this.openId, values.phone, values.sms,'')
        .then((res) => {
          if (res.content.status === 200 || res.content.statusCode === 200 ) {
            this.$toast.success("绑定成功");
            this.$store.dispatch('getUserInfo').then(userinfo => {
              this.isEditMode = false;
              if (this.$route.query.autoback == '1') {
                this.$router.go(-1);
              }
            });
          } else {
            this.$toast('绑定失败：'+res.content.message);
          }
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            message: err.message,
          });
        })
        .finally(() => this.submiting = false);
    }, 500, {leading: true}),
    onFailed({values, errors}) {
      this.$notify({ type: 'danger', message: errors[0].message});
    },
    getSMSCode: _.debounce(function() {
      wXGZHService_sendWxgzhDxyzm(this.form.phone, this.openId).then(res => {
        this.$notify({type: res.content.status == 200 ? 'success' : 'danger', message: res.content.message});
      }).catch(err => {
        this.$notify({ type: 'danger', message: err.message});
      });
    }, 500, {leading: true}),
  },
  mounted() {
  }
};
</script>